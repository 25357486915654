<template>
  <Container>
    <RichText :content="text" />
  </Container>
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    name: 'AlchemyTextBlock',
    mixins: [AlchemyElement],
    computed: {
      text() {
        return this.getRichtext("text")
      },
    },
  }
</script>

<style lang="scss">
  /* We can't use scoped here as we use v-html */

  .text-block-text {
    h1 {
      @include type('h1');
    }
    h2 {
      @include type('h2');
    }
    h3 {
      @include type('h3');
    }
    h4 {
      @include type('h4');
    }
    h5 {
      @include type('h5');
    }
    h6 {
      @include type('h6');
    }
    strong {
      font-weight: bold;
    }
    section {
      &.centered {
        max-width: $content-width-small;
        margin: 0 auto;
      }
      &.small {
        @include type('md');
      }
    }

    ul {
      li {
        padding-left: $base-spacing * 6;
        display: block;
        margin-bottom: .5em;
        position: relative;

        &:before {
          content: " ";
          position: absolute;
          vertical-align: middle;
          display: inline-block;
          left: $base-spacing * -2;
          top: $base-spacing * 2.5;
          width: $base-spacing * 2;
          height: $base-spacing * 2;
          border-radius: $base-spacing * 2;
          background-color: $blue;
        }
      }
    }

    .lead {
      @include type('lead');
      color: var(--heading);
    }

    .system-font-paragraph {
      font-family: $font-family-default;
    }

    .huge-serif-paragraph {
      @include type('blockquote');
    }
  }
</style>
